/**
 * MinersFactory | welcome header component
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

const WelcomeHeader = () => {
    const data = useStaticQuery(graphql`
        query {
            splashBg: file(relativePath: { eq: "background.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1920) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)

    return (
        <BackgroundImage
            Tag="div"
            className="welcome-header"
            fluid={data.splashBg.childImageSharp.fluid}
        >
            <section>
                <h1>MinersFactory Minecraft: BE Server</h1>
                <h2>Willkommen auf unserer neuen Website!</h2>
                <a
                    href="minecraft://?addExternalServer=MinersFactory|play.minersfactory.de:19132"
                    className="btn btn-outline-light"
                >
                    Auf dem Server spielen!
                </a>
            </section>
        </BackgroundImage>
    )
}

export default WelcomeHeader
