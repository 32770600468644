/**
 * MinersFactory | index page
 */

import React from "react"

import Main from "./../templates/main"
import SEO from "./../components/seo"
import WelcomeHeader from "../components/welcomeheader"

import "./../components/index.css"

const IndexPage = () => (
    <Main>
        <SEO title="Willkommen" />
        <WelcomeHeader />
        <div className="container">
            <section>
                <h1>Willkommen bei MinersFactory!</h1>
                <p>
                    Wir sind ein Minecraft: Bedrock Edition - Server und bieten
                    euch Citybuild-Server in den Spielmodi Survival und Kreativ
                    sowie einen FFA-Server. Auch neue Funktionen sind geplant
                    und kommen bald.
                </p>
                <a
                    href="https://minecraftpocket-servers.com/server/86095/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-outline-primary"
                >
                    Jetzt für MinersFactory voten!
                </a>
            </section>
        </div>
    </Main>
)

export default IndexPage
